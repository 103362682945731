class Storage {
    ls_disabled: boolean = false;

    constructor() {
        try {
            if (typeof window.localStorage == 'undefined') {
                this.disable_storage();
            }
        } catch {
            this.disable_storage();
        }
    }

    disable_storage() {
        console.log('no access to localStorage');
        this.ls_disabled = true;
        const localStorageMock = (() => {
            let store: Record<string, string> = {};

            return {
                getItem(key: string) {
                    return store[key] || null;
                },
                setItem(key: string, value: string) {
                    store[key] = value.toString();
                },
                removeItem(key: string) {
                    delete store[key];
                },
                clear() {
                    store = {};
                },
            };
        })();

        Object.defineProperty(window, 'localStorage', {
            value: localStorageMock,
        });
    }

    set(key: string, data: string) {
        try {
            localStorage.setItem(key, data);
        } catch {}
    }

    get(key: string) {
        try {
            return localStorage.getItem(key);
        } catch {}
        return undefined;
    }

    remove(key: string) {
        try {
            return localStorage.removeItem(key);
        } catch {}
    }
}

const storage = new Storage();

export default storage;
