import { get_json, post_json } from '../fetch';
import settings from '../settings';

export enum RouletteTier {
    red = 'red',
    black = 'black',
    zero = 'zero',
}

export interface RouletteGameConfig {
    multipliers: Record<RouletteTier, number>;
    sections: string[];
}

export interface RouletteGameSession {
    index: number;
    tier: RouletteTier;
    balance: number;
}

class RouletteGameService {
    async get_config(): Promise<RouletteGameConfig | null> {
        try {
            const response = await get_json(`${settings.wow_server_url}/games/roulette/config`);
            //console.log(response);
            if (response?.config) {
                return response.config;
            }
        } catch (err) {
            console.error('failed to get roulette game config', err);
        }
        return null;
    }
    async get_session(bet: number, tier_id: string, access_token: string): Promise<RouletteGameSession | null> {
        try {
            const response = await post_json(
                `${settings.wow_server_url}/games/roulette/session`,
                { bet, tier_id },
                access_token
            );
            //console.log(response);
            if (response?.success) {
                return {
                    index: response.index,
                    tier: response.tier,
                    balance: response.balance,
                };
            }
        } catch (err) {
            console.error('failed to get roulette game session', err);
        }
        return null;
    }
}

const roulette_game_service = new RouletteGameService();
export default roulette_game_service;
