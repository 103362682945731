import { FC, InputHTMLAttributes } from 'react';

import './input.css';

interface InputProps extends React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string;
    id?: string;
    value: string;
}

export const Input: FC<InputProps> = ({ label, value, id, ...rest }) => {
    return (
        <div className='input-container'>
            {label ? <label htmlFor={id}>{label}</label> : null}
            <input id={id} type='text' value={value} {...rest} />
        </div>
    );
};
