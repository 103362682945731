import { FC } from 'react';
import './dice.css';

interface Props {
    value: number;
}

export const Dice: FC<Props> = ({ value }) => {
    let pips = Number.isInteger(value)
        ? Array(value)
              .fill(0)
              .map((_, index) => <span className='pip' key={index} />)
        : null;
    return (
        <div className='dice'>
            <div className='face'>{pips}</div>
        </div>
    );
};
