import { FC, useCallback, useState } from 'react';
import { useUserContext } from '../../contexts/user_context';
import { Dialog } from './dialog';
import { Input } from '../common/input';
import { useAppContext } from '../../contexts/app_context';

export const RegisterDialog: FC = () => {
    const [email, set_email] = useState('');
    const [username, set_username] = useState('');
    const [password, set_password] = useState('');
    const { register } = useUserContext();
    const { cancel_action } = useAppContext();

    const on_register = useCallback(async () => {
        if (await register(email.trim().toLowerCase(), username.trim(), password.trim())) {
            cancel_action();
        }
    }, [email, username, password, register, cancel_action]);

    return (
        <Dialog title='Register' onClose={cancel_action}>
            <div className='auth-dialog'>
                <Input
                    label='Email'
                    id='email'
                    value={email}
                    onChange={(event) => set_email(event.target.value)}
                />
                <Input
                    label='Username'
                    id='username'
                    value={username}
                    onChange={(event) => set_username(event.target.value)}
                />
                <Input
                    label='Password'
                    type='password'
                    id='password'
                    value={password}
                    onChange={(event) => set_password(event.target.value)}
                />
                <button
                    onClick={on_register}
                    disabled={!email.trim() || !username.trim() || !password.trim()}
                    className='primary-button'
                >
                    Register
                </button>
            </div>
        </Dialog>
    );
};
