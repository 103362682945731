import { FC, useMemo } from 'react';
import { DeviceNavbar } from './common/device_navbar';
import { ScreenType, useAppContext } from '../contexts/app_context';
import { MAIN_MENU } from '../app_routing';

export const Footer: FC = () => {
    const { screen_type } = useAppContext();

    const show_menu = useMemo(() => {
        switch (screen_type) {
            case ScreenType.mobile_portrait:
            case ScreenType.mobile_landscape:
                return true;

            default:
                return false;
        }
    }, [screen_type]);

    return (
        <>
            <div style={{ flex: ' 1 1 auto' }} />
            <footer>{'(c) wowhaus'}</footer>
            {show_menu && <DeviceNavbar items={MAIN_MENU} />}
        </>
    );
};
