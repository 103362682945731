interface Props {
    icon: string;
    weight?: number;
    color?: string;
    size?: string;
    className?: string;
    onClick?: () => void;
}

export function MaterialIcon({ icon, weight = 400, color = '#fff', size = '20px', ...res }: Props) {
    return (
        <span
            style={{
                fontFamily: 'Material Symbols Rounded',
                fontSize: size,
                fontVariationSettings: `"FILL" 0, "wght" ${weight}, "GRAD" 0, "opsz" 48`,
                color,
            }}
            {...res}
        >
            {icon}
        </span>
    );
}
