import { FC, useCallback, useState } from 'react';
import { useUserContext } from '../../contexts/user_context';
import { Dialog } from './dialog';
import { Input } from '../common/input';
import { useAppContext } from '../../contexts/app_context';

export const LoginDialog: FC = () => {
    const [email, set_email] = useState('');
    const [password, set_password] = useState('');
    const { login } = useUserContext();
    const { cancel_action } = useAppContext();

    const on_login = useCallback(async () => {
        if (await login(email.trim().toLowerCase(), password.trim())) {
            cancel_action();
        }
    }, [email, password, login, cancel_action]);

    return (
        <Dialog title='Login' onClose={cancel_action}>
            <div className='auth-dialog'>
                <Input
                    label='Email'
                    id='email'
                    value={email}
                    onChange={(event) => set_email(event.target.value)}
                />
                <Input
                    label='Password'
                    type='password'
                    id='password'
                    value={password}
                    onChange={(event) => set_password(event.target.value)}
                />
                <button className='primary-button' onClick={on_login} disabled={!email.trim() || !password.trim()}>
                    Login
                </button>
            </div>
        </Dialog>
    );
};
