import { FC } from 'react';
import { Header } from './header';
import { ActionController } from './action_controller_component';
import { Outlet } from 'react-router-dom';
import { Footer } from './footer';

export const AppLayoutComponent: FC = () => {
    return (
        <div className='page-container'>
            <Header />
            <main>
                <Outlet />
            </main>
            <ActionController />
            <Footer />
        </div>
    );
};
