import { FC } from 'react';
import { useAppContext } from '../contexts/app_context';

import './login_component.css';

export const LoginComponent: FC = () => {
    const { open_login_dialog, open_register_dialog } = useAppContext();

    return (
        <div className='login-component'>
            <img className='logo-big' src='/assets/logo-big.png' alt='' />
            <div className='login-text'>Please sign-in to continue</div>
            <div className='button-wrap'>
                <button className='login-button' onClick={open_login_dialog}>Login</button>
                <div>or</div>
                <button className='primary-button register-button' onClick={open_register_dialog}>
                    Register
                </button>
            </div>
        </div>
    );
};
