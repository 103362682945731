import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import './main_page.css';
import { SpinGame } from '../games/spin_game';

export const MainPage: FC = () => {
    const navigate = useNavigate();

    return (
        <div className='main-page'>
            <div
                className='banner-lg'
                onClick={() => navigate('/quests')}
                style={{ backgroundImage: `url("/assets/quests_banner.jpg")` }}
            >
                <div className='banner-text'>New claimr quest!</div>
                <button className='primary-button'>Play now!</button>
            </div>
            <SpinGame />
        </div>
    );
};
