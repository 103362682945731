import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BETS } from '../../constants';
import dices_game_service, { DicesGameSession } from '../../services/dices_game_service';
import { useUserContext } from '../../contexts/user_context';
import { BetsComponent } from './bets_component';
import { DicesComponent } from './dices_component';

enum GameState {
    betting,
    rolling,
}

const S = 3;

export const DicesGame: FC = () => {
    const { username, access_token, update_balance } = useUserContext();
    const [state, set_state] = useState(GameState.betting);
    const [bet, set_bet] = useState(BETS[0]);
    const [session, set_session] = useState<DicesGameSession | null>(null);
    const [ready, set_ready] = useState(false);

    useEffect(() => {
        return () => {
            if (session) {
                update_balance(session.balance);
            }
        };
    }, [session, update_balance]);

    const on_bet = useCallback(async () => {
        set_state(GameState.rolling);
        const session = await dices_game_service.get_session(bet, access_token);
        if (session) {
            set_session(session);
            setTimeout(() => {
                set_ready(true);
            }, S * 1000);
        }
    }, [bet, access_token]);

    const on_continue = useCallback(() => {
        if (!session) return;
        update_balance(session.balance);
        set_state(GameState.betting);
        set_session(null);
        set_ready(false);
    }, [session, update_balance]);

    const user_points = useMemo(() => {
        return session ? session.user_dices[0] + session.user_dices[1] + session.user_dices[2] : 0;
    }, [session]);

    const wow_points = useMemo(() => {
        return session ? session.wow_dices[0] + session.wow_dices[1] + session.wow_dices[2] : 0;
    }, [session]);

    const user_won = useMemo(() => {
        return user_points > wow_points;
    }, [user_points, wow_points]);

    return (
        <div className='game'>
            <div className='game-title'>Dices</div>
            {/*<div>
                {username} {balance}$ {session ? (user_won ? 'WIN' : 'LOSE') : ''}
            </div>*/}
            <div className='horizontal-center'>
                {!ready && <div className='bet-text'>{bet}$</div>}
                {ready && <div className='bet-text'>{user_won ? `+${bet} WIN`:`-${bet} LOST`}$</div>}
            </div>
            <div className='game-field'>
                {state === GameState.betting && <BetsComponent bet={bet} setBet={set_bet} />}
                {state === GameState.rolling && (
                    <>
                        {session && (
                            <>
                                <div className='username'>{username}</div>
                                <DicesComponent values={session.user_dices} timer={S} />
                                <div className='username'>WOWHAUS</div>
                                <DicesComponent values={session.wow_dices} timer={S} />
                            </>
                        )}
                    </>
                )}
            </div>
            {state === GameState.betting && (
                <button className='bet-button' onClick={on_bet}>
                    Roll Dices
                </button>
            )}
            {state === GameState.rolling && (
                <button className='continue-button' disabled={!ready} onClick={on_continue}>
                    Continue
                </button>
            )}
        </div>
    );
};
