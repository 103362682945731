import { FC, Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import { CLAIMR_CONTAINER_ID } from '../constants';
import { useUserContext } from '../contexts/user_context';

const SCRIPT_ID = 'claimr-script';

interface Props {
    user_token: string;
    children: ReactNode;
}

export const ClaimrWrapper: FC<Props> = ({ user_token, children }) => {
    const [stored_token, store_token] = useState('');
    const { update_quest_info } = useUserContext();

    useEffect(() => {
        const process_message = async (event: MessageEvent<any>) => {
            if (event.data.event === 'widget::user') {
                update_quest_info(event.data.xp, event.data.xp_mul, event.data.rank);
            }
        };
        window.addEventListener('message', process_message);

        return () => {
            window.removeEventListener('message', process_message);
        };
    }, [update_quest_info]);

    const handle_script_creation = useCallback(() => {
        if (!user_token) return;

        let script = document.getElementById(SCRIPT_ID);

        if (script && stored_token && stored_token !== user_token) {
            script.parentElement?.removeChild(script);
        }

        store_token(user_token);

        if (!script) {
            script = document.createElement('script');

            script.setAttribute('id', SCRIPT_ID);
            script.setAttribute('data-container', CLAIMR_CONTAINER_ID);
            script.setAttribute('src', 'https://widgets.claimr.io/claimr.min.js');
            script.setAttribute('data-organization', 'wowhaus');
            script.setAttribute('data-campaign', 'demo');
            script.setAttribute('data-addons', 'eap,eup,pvm,dcc');
            script.setAttribute('data-autoresize', 'true');
            script.setAttribute('data-user-token', user_token);
            //script.setAttribute('data-platform', 'igame');

            document.body.appendChild(script);
        }
    }, [stored_token, user_token]);

    useEffect(() => {
        handle_script_creation();
    }, [handle_script_creation]);

    return <Fragment>{children}</Fragment>;
};
