import { FC, useEffect } from 'react';
import { CLAIMR_CONTAINER_ID } from '../../constants';
import { ClaimrWrapper } from '../claimr_wrapper';
import { useUserContext } from '../../contexts/user_context';
import './quests_page.css';

export const QuestsPage: FC = () => {
    const { user_token, access_token, fetch_user_token } = useUserContext();

    useEffect(() => {
        if (!user_token) {
            fetch_user_token();
        }
    }, [user_token, access_token, fetch_user_token]);

    return (
        <div className='quests-page'>
            {user_token && (
                <ClaimrWrapper user_token={user_token}>
                    <div className='claimr-container'>
                        <div id={CLAIMR_CONTAINER_ID} />
                    </div>
                </ClaimrWrapper>
            )}
        </div>
    );
};
