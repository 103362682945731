import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useUserContext } from '../../contexts/user_context';
import { BETS } from '../../constants';
import roulette_game_service, {
    RouletteGameConfig,
    RouletteGameSession,
} from '../../services/roulette_game_service';
import { WheelComponent } from './wheel_component';
import { BetsComponent } from './bets_component';

enum Tier {
    red = 'red',
    black = 'black',
    zero = 'zero',
}

enum GameState {
    betting,
    rolling,
}

const TIER_COLOR = {
    [Tier.red]: '#ba4d4e',
    [Tier.black]: '#1f1f1f',
    [Tier.zero]: '#14c187',
};

const S = 3;
const M = 3;

export const RouletteGame: FC = () => {
    const { username, balance, access_token, update_balance } = useUserContext();
    const [state, set_state] = useState(GameState.betting);
    const [bet, set_bet] = useState(BETS[0]);
    const [tier, set_tier] = useState('');
    const [config, set_config] = useState<RouletteGameConfig | null>(null);
    const [session, set_session] = useState<RouletteGameSession | null>(null);
    const [rotation, set_rotation] = useState(0);
    const [ready, set_ready] = useState(false);

    const fetch_config = useCallback(async () => {
        const config = await roulette_game_service.get_config();
        if (config) {
            set_config(config);
            set_tier(Object.keys(config.multipliers)[0]);
        }
    }, []);

    const tiers = useMemo(() => {
        return config ? Object.keys(config.multipliers) : ['undefined'];
    }, [config]);

    useEffect(() => {
        fetch_config();
    }, [fetch_config]);

    useEffect(() => {
        return () => {
            if (session) {
                update_balance(session.balance);
            }
        };
    }, [session, update_balance]);

    const on_bet = useCallback(async () => {
        set_state(GameState.rolling);
        const session = await roulette_game_service.get_session(bet, tier, access_token);
        if (session) {
            set_session(session);
            const total_sections = config!.sections.length;
            const slice_angle = 360 / total_sections;
            const chosen_slice_center = session.index * slice_angle + slice_angle / 2;
            set_rotation(Math.floor(rotation / 360) * 360 + (M * 360 - chosen_slice_center));
            setTimeout(() => {
                set_ready(true);
            }, S * 1000);
        }
    }, [bet, tier, config, rotation, access_token]);

    const on_continue = useCallback(() => {
        if (!session) return;
        update_balance(session.balance);
        set_state(GameState.betting);
        set_session(null);
        set_ready(false);
    }, [session, update_balance]);

    return (
        <div className='game'>
            <div>Roulette</div>
            <div>{balance}$</div>
            {config && (
                <>
                    <div>
                        {bet}$ / {tier}
                    </div>
                    <div
                        style={{
                            position: 'relative',
                            width: 300,
                            height: 300,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: `transform ${S}s cubic-bezier(0.32, 0.64, 0.45, 1)`,
                                transform: `rotate(${rotation}deg)`,
                            }}
                        >
                            <WheelComponent
                                sections={config.sections.map((section, index) => ({
                                    text: index.toString(),
                                    color: TIER_COLOR[section as Tier],
                                }))}
                                width={300}
                                height={300}
                                radius={150}
                                rotation={0}
                            />
                        </div>
                    </div>
                    {state === GameState.betting && (
                        <>
                            <div className='horizontal-center'>
                                {tiers.map((tier) => (
                                    <button key={tier} onClick={() => set_tier(tier)}>
                                        {tier}
                                    </button>
                                ))}
                            </div>
                            <BetsComponent bet={bet} setBet={set_bet} />
                            <button onClick={on_bet}>Roll Roulette</button>
                        </>
                    )}
                    {state === GameState.rolling && (
                        <>
                            {session && ready && (
                                <>
                                    <div>
                                        {username} {session.tier === tier ? 'WIN' : 'LOST'}
                                    </div>
                                    <div>{session.tier}</div>
                                    <button onClick={on_continue}>Continue</button>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};
