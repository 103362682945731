import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import './games_gallery.css';

interface CardProps {
    cover: string;
    title: string;
    game: string;
}

const GameCard: FC<CardProps> = ({ cover, game, title }) => {
    const navigate = useNavigate();

    const handle_navigate = (game: string) => {
        navigate(`games/${game}`);
    };

    return (
        <div className='game-card' onClick={() => handle_navigate(game)}>
            <img src={cover} alt='' />
            <div className={'game-name'}>{title}</div>
            <button className='primary-button'>Play</button>
        </div>
    );
};

export const GamesGallery: FC = () => {
    return (
        <>
            <div className='games-page'>
                <div className='game-category'>
                    <GameCard title='Dices' cover='./assets/game_banner_0.png' game='dices' />
                    <GameCard title='Roulette' cover='./assets/game_banner_1.png' game='roulette' />
                </div>
            </div>
        </>
    );
};
