import { FC, useMemo } from 'react';
import { useUserContext } from '../contexts/user_context';
import { Link } from 'react-router-dom';
import { Menu } from './common/menu';
import { ScreenType, useAppContext } from '../contexts/app_context';
import { MAIN_MENU } from '../app_routing';
import './header.css';

export const Header: FC = () => {
    const { username, access_token, xp, balance, logout } = useUserContext();
    const { open_login_dialog, open_register_dialog, screen_type } = useAppContext();

    const show_menu = useMemo(() => {
        switch (screen_type) {
            case ScreenType.mobile_portrait:
            case ScreenType.mobile_landscape:
                return false;

            default:
                return true;
        }
    }, [screen_type]);

    return (
        <>
            <header>
                <Link className='logo' to='/'>
                    <img src='/assets/logo.png' alt='' />
                </Link>
                {show_menu && (
                    <>
                        <div style={{ flex: ' 1 1 auto' }} />
                        <Menu items={MAIN_MENU} />
                    </>
                )}
                <div style={{ flex: ' 1 1 auto' }} />
                {access_token ? (
                    <div className='user-info'>
                        <div className='info-container'>
                            <div className='user-name'>{username}</div>
                            <div className='horizontal-center gap-8'>
                            <div className='user-points'>{xp} pts</div>
                            <div className='user-balance'>{balance}$</div>
                            </div>
                        </div>
                        <button className='logout-button' onClick={logout}>Logout</button>
                    </div>
                ) : (
                    <>
                        <button className='login-button' onClick={open_login_dialog}>Login</button>
                        <button className='primary-button register-button' onClick={open_register_dialog}>
                            Register
                        </button>
                    </>
                )}
            </header>
        </>
    );
};
