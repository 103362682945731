import { post_json } from '../fetch';
import settings from '../settings';

export interface DicesGameSession {
    balance: number;
    wow_dices: number[];
    user_dices: number[];
}

class DicesGameService {
    async get_session(bet: number, access_token: string): Promise<DicesGameSession | null> {
        try {
            const response = await post_json(
                `${settings.wow_server_url}/games/dices/session`,
                { bet },
                access_token
            );
            //console.log(response);
            if (response?.success) {
                return {
                    balance: response.balance,
                    wow_dices: response.wow_dices,
                    user_dices: response.user_dices,
                };
            }
        } catch (err) {
            console.error('failed to get dices game session', err);
        }
        return null;
    }
}

const dices_game_service = new DicesGameService();
export default dices_game_service;
