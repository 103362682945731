import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { http } from 'wagmi';
import { mainnet, sepolia, arbitrum, arbitrumSepolia } from 'wagmi/chains';

const projectId = '3fbb6bba6f1de962d911bb5b5c9dba88';

export const config = getDefaultConfig({
    appName: 'claimr integration example',
    projectId,
    chains: [mainnet, arbitrum, sepolia, arbitrumSepolia],
    transports: {
        [mainnet.id]: http(),
        [arbitrum.id]: http(),
        [sepolia.id]: http(),
        [arbitrumSepolia.id]: http(),
    },
});
