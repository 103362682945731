import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MaterialIcon } from './material_icon';
import './device_navbar.css';
import { MenuItem } from './menu';

interface Props {
    items: MenuItem[];
}

export const DeviceNavbar: FC<Props> = ({ items }) => {
    const location = useLocation();

    return (
        <div className='device-navbar'>
            {items.map(({ path, label, icon }, index) => (
                <Link key={index} data-is-active={String(Number(location.pathname === path))} to={path}>
                    <MaterialIcon size='24px' icon={icon} />
                    <span>{label}</span>
                </Link>
            ))}
        </div>
    );
};
