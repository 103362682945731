import { FC } from 'react';
import './dialog.css';

interface DialogProps {
    title: string;
    children?: React.ReactNode;
    onClose?: () => void;
}

export const Dialog: FC<DialogProps> = ({ title, children, onClose }) => {
    return (
        <div className='dialog-overlay'>
            <div className='dialog'>
                <div className='dialog-header'>
                    <span className='title'>{title}</span>
                    {onClose && (
                        <button onClick={onClose}>
                            x
                        </button>
                    )}
                </div>
                {children}
            </div>
        </div>
    );
};
