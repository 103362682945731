import '@rainbow-me/rainbowkit/styles.css';

import { FC } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';

import { config } from './wagmi';
import { AppRouting } from './app_routing';
import { AppProvider } from './contexts/app_context';
import { UserProvider } from './contexts/user_context';

const client = new QueryClient();

export const ExampleApp: FC = () => {
    return (
        <AppProvider>
            <UserProvider>
                <WagmiProvider config={config}>
                    <QueryClientProvider client={client}>
                        <RainbowKitProvider>
                            <AppRouting />
                        </RainbowKitProvider>
                    </QueryClientProvider>
                </WagmiProvider>
            </UserProvider>
        </AppProvider>
    );
};

export default ExampleApp;
