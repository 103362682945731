import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import ExampleApp from './example_app';
import './index.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <StrictMode>
        <ExampleApp />
    </StrictMode>
);
