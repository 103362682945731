import { FC, useEffect, useMemo, useState } from 'react';
import { Dice } from './dice';
import './dices_component.css';

interface Props {
    values: number[];
    timer: number;
}

export const DicesComponent: FC<Props> = ({ values, timer }) => {
    const [visible, set_visible] = useState<boolean[]>([]);

    useEffect(() => {
        set_visible(Array(values.length).map(() => false));
        const show_dice = (index: number) => {
            set_visible((state) => {
                state[index] = true;
                return [...state];
            });
        };
        const delay = (timer / values.length) * 1000;
        setTimeout(() => show_dice(0), delay);
        for (let cn = 1; cn < values.length; ++cn) {
            setTimeout(() => {
                setTimeout(() => show_dice(cn), delay);
            }, delay * cn);
        }
    }, [timer, values]);

    const points = useMemo(() => {
        let result = 0;
        for (let cn = 0; cn < values.length; ++cn) {
            if (visible[cn]) result += values[cn];
        }
        return result;
    }, [values, visible]);

    return (
        <>
            <div className='horizontal-center gap-16'>
                {values.map((dice, index) => (
                    <Dice value={visible[index] ? dice : 0} key={index} />
                ))}
            </div>
            <div className='dices-points'>{points}</div>
        </>
    );
};
