import { FC } from 'react';
import { BETS } from '../../constants';
import './games.css';

interface Props {
    bet: number;
    setBet: (bet: number) => void;
}

export const BetsComponent: FC<Props> = ({ bet, setBet }) => {
    return (
        <>
            <div className='horizontal-center gap-8'>
                {BETS.map((item) => (
                    <button
                        key={item}
                        onClick={() => setBet(item)}
                        className={bet === item ? 'bet-item-selected' : 'bet-item'}
                    >
                        {item}$
                    </button>
                ))}
            </div>
        </>
    );
};
