import { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AppLayoutComponent } from './components/app_layout_component';
import { GamesGallery } from './components/pages/games_gallery';
import { MainPage } from './components/pages/main_page';
import { QuestsPage } from './components/pages/quests_page';
import { AuthWrapper } from './components/auth_wrapper';
import { DicesGame } from './components/games/dices_game';
import { RouletteGame } from './components/games/roulette_game';
import { MenuItem } from './components/common/menu';

export const MAIN_MENU: MenuItem[] = [
    { path: '/', label: 'Home', icon: 'home' },
    { path: '/games', label: 'Games', icon: 'playing_cards' },
    { path: '/quests', label: 'Quests', icon: 'emoji_events' },
];

const router = createBrowserRouter([
    {
        path: '/',
        element: <AppLayoutComponent />,
        children: [
            {
                index: true,
                element: <MainPage />,
            },
            {
                path: 'games',
                children: [
                    {
                        index: true,
                        element: <GamesGallery />,
                    },
                    {
                        path: `:gid`,
                        element: <AuthWrapper />,
                        children: [
                            {
                                path: `dices`,
                                element: <DicesGame />,
                            },
                            {
                                path: `roulette`,
                                element: <RouletteGame />,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'quests',
                element: <AuthWrapper />,
                children: [
                    {
                        index: true,
                        element: <QuestsPage />,
                    },
                ],
            },
        ],
    },
]);

export const AppRouting: FC = () => {
    return <RouterProvider router={router} />;
};
