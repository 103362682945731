import { FC } from 'react';

export interface WheelSection {
    text: string;
    color: string;
}

interface Props {
    sections: WheelSection[];
    width?: number;
    height?: number;
    radius?: number;
    rotation?: number;
}

function deg_to_rad(deg: number) {
    return (Math.PI * deg) / 180;
}

function polar_to_cartesian(cx: number, cy: number, radius: number, angle_in_degrees: number) {
    const angleInRadians = deg_to_rad(angle_in_degrees - 90);
    return {
        x: cx + radius * Math.cos(angleInRadians),
        y: cy + radius * Math.sin(angleInRadians),
    };
}

function describe_arc(cx: number, cy: number, r: number, start_angle: number, end_angle: number) {
    const start = polar_to_cartesian(cx, cy, r, end_angle);
    const end = polar_to_cartesian(cx, cy, r, start_angle);

    const large_arc_flag = end_angle - start_angle <= 180 ? '0' : '1';

    const d = [
        `M ${start.x},${start.y}`,
        `A ${r},${r} 0 ${large_arc_flag} 0 ${end.x},${end.y}`,
        `L ${cx},${cy}`,
        'Z',
    ].join(' ');

    return d;
}

export const WheelComponent: FC<Props> = ({ sections, width = 400, height = 400, radius = 150, rotation = 0 }) => {
    const cx = width / 2;
    const cy = height / 2;

    const total_sections = sections.length;
    const slice_angle = 360 / total_sections;

    return (
        <svg width={width} height={height}>
            <g transform={`rotate(${rotation}, ${cx}, ${cy})`}>
                {sections.map((section, i) => {
                    const start_angle = i * slice_angle;
                    const end_angle = (i + 1) * slice_angle;
                    const path_data = describe_arc(cx, cy, radius, start_angle, end_angle);

                    const mid_angle = start_angle + slice_angle / 2;
                    const mid_radians = deg_to_rad(mid_angle - 90);

                    const text_radius = radius * 0.85;
                    const text_x = cx + text_radius * Math.cos(mid_radians);
                    const text_y = cy + text_radius * Math.sin(mid_radians);

                    const rotate_text = mid_angle;

                    return (
                        <g key={i}>
                            <path
                                d={path_data}
                                fill={section.color}
                                //stroke='#0008'
                                //strokeWidth={1}
                            />
                            <text
                                x={text_x}
                                y={text_y}
                                fill='#fff'
                                fontSize='16'
                                textAnchor='middle'
                                alignmentBaseline='middle'
                                transform={`rotate(${rotate_text}, ${text_x}, ${text_y})`}
                                style={{ fontWeight: 'bold' }}
                            >
                                {section.text}
                            </text>
                        </g>
                    );
                })}
            </g>

            {/*<circle cx={cx} cy={cy} r={15} fill='#333' />*/}

            <circle cx={cx} cy={cy} r={radius} fill='none' stroke='#0008' strokeWidth={2} />
        </svg>
    );
};
