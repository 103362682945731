import { FC } from 'react';
import { useUserContext } from '../contexts/user_context';
import { Outlet } from 'react-router-dom';
import { LoginComponent } from './login_component';

export const AuthWrapper: FC = () => {
    const { access_token } = useUserContext();

    return access_token ? <Outlet /> : <LoginComponent />;
};
